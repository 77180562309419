<template>
  <div>
    <topDiv2 @openLogin="openLogin"></topDiv2>
    <searchFrame />
    <login
      style="width: 50vw; height: 50vh; z-index: 10000; position: fixed; right: 0"
      v-if="showLogin"
    ></login>
  </div>
</template>

<script>
// import mapLayer from "@/components/mapLayer";
import login from "../views/login/login.vue";
import topDiv2 from "@/components/topDiv2";
import searchFrame from "@/components/searchComponents/searchFrame";
export default {
  name: "test",
  components: {
    topDiv2,
    login,
    // mapLayer, // 注册 TopSection 组件
    searchFrame,
  },
  data() {
    return {
      showLogin: false,
    };
  },
  methods: {
    openLogin() {
      this.showLogin = true;
      console.log(this.showLogin);
    },
  },
};
</script>

<style scoped></style>

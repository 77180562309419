<template>
  <div class="top-section">
    <img src="@/assets/img/logo3.png" v-show="isOHXLogo" alt="Logo" class="logo" />
    <img src="@/assets/img/pre/logo.png" v-show="!isOHXLogo" alt="Logo" class="logopre" />
    <!-- 封装"我的船队"在一个span标签中，并添加点击事件和样式 -->
    <!-- <ul class="menu">
      <li class="menu-item" :class="{ active: showMyArea }" @click="navigateToArea">区域</li>
      <li class="menu-item" :class="{ active: showMyFleet }" @click="navigateToFleet">船队</li>
    </ul> -->
    <el-button v-if="!isLog" style="height: 30px" type="primary" @click="routeToLogin"
      >登录</el-button
    >
    <i
      v-if="isLog"
      v-show="!false"
      class="el-icon-user-solid user-icon"
      @click="changePartInfo"
    ></i>

    <!-- Your content goes here -->
  </div>
</template>

<script>
export default {
  name: "topDiv2",
  mounted() {
    this.isOHXLogo = window.isOHXLogo;
  },
  created() {
    this.isUserLoggedIn();
    // console.log(this.isLog);
  },
  data() {
    return {
      // showMyPartInfo: false,
      // isLog:false,
      temp: false,
      isOHXLogo: null
    };
  },
  computed: {
    isLog() {
      return this.$store.state.isLog;
    },
    showMyFleet() {
      return this.$store.state.showMyFleet;
    },
    showMyArea() {
      return this.$store.state.showMyArea;
    },
  },

  methods: {
    //安全性有待商榷，后端应当进行判断

    isUserLoggedIn() {
      const accessToken = localStorage.getItem("accessToken");
      const expireTime = localStorage.getItem("expireTime");
      const currentTime = new Date().getTime();
      if (accessToken && (!expireTime || parseInt(expireTime, 10) > currentTime)) {
        this.$store.commit("SET_LOGIN_STATE", true);
      } else {
        this.$store.commit("SET_LOGIN_STATE", false);
      }
    },
    routeToLogin() {
      this.$router.push("/login");
      // console.log("login");
      // this.$emit("openLogin");
    },
    changePartInfo() {
      this.temp = this.$store.state.showMyPartInfo;
      if (this.temp) {
        this.$store.commit("SET_PART_INFO_STATE", false);
      } else {
        this.$store.commit("SET_PART_INFO_STATE", true);
      }
      console.log(this.$store.state.showMyPartInfo);
      // return this.temp;
    },

    navigateToArea() {
      this.$store.commit("SET_SHOW_MYAREA", !this.showMyArea);
      //   this.$emit("show-my-fleet");
      console.log("showMYAREA");
      // 在这里定义你希望点击"我的船队"时执行的操作，比如路由跳转
      // this.$router.push({ path: '/myinfo' }); // 示例，替换为实际的路径
    },
    navigateToFleet() {
      this.$store.commit("SET_SHOW_MYFLEET", !this.showMyFleet);
      //   this.$emit("show-my-fleet");
      console.log("showFLEETMY");
      // 在这里定义你希望点击"我的船队"时执行的操作，比如路由跳转
      // this.$router.push({ path: '/myinfo' }); // 示例，替换为实际的路径
    },
  },
};
</script>

<style scoped>
.top-section span:hover {
  color: orange; /* 举例改变颜色，你可以根据需要调整 */
}
.menu {
  cursor: pointer;
  margin-right: 20px;
  list-style: none;
  height: 55px;
  /* transition: color 0.3s ease; */
}

.menu-item {
  /* cursor: pointer; */
  /* background: #fff; */
  height: 55px;
  line-height: 55px;
  padding-right: 10px;
  padding-left: 10px;
  float: left;
}
.active {
  cursor: pointer;
  /* background: #fff; */
  background: #40617c;
  height: 100%;
  font-weight: bold;
}

.menu-item:hover {
  cursor: pointer;
  /* background: #fff; */
  background: #40617c;
  height: 100%;
  font-weight: bold;
}
.top-section {
  height: 55px;
  /* line-height: 60px; */
  background-color: #2a3f7e;
  color: #ffffff;
  padding: 20px;
  margin: 0; /* 添加这行，重置外边距为0 */
  width: 100vw;
  box-sizing: border-box;
  position: fixed;
  display: flex; /* 启用Flexbox布局 */
  align-items: center; /* 垂直居中 */
  justify-content: flex-end; /* 水平右对齐 */
  left: 0;
  top:   0;
  z-index: 200;
}

.logo {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0%, -50%);
  max-width: 20%;
  max-height: 90%;
  margin-left: 20px;
}



.logopre {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0%, -50%);
  max-width: 160px;
  margin-left: 25px;
  margin-top: 6px;
}

.user-icon {
  display: inline-block;
  border: 2px solid white;
  border-radius: 50%;
  padding: 5px;
  background-color: transparent;
  cursor: pointer;
}
</style>
